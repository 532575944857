/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import navyLogo from "@/assets/logomark_navy.svg";
import whiteLogo from "@/assets/logomark_white.svg";

/**
 * @description Renders the home page with redirect message
 */
function HomePage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-background to-background/95">
      <div className="w-full max-w-lg mx-auto p-8 rounded-xl bg-card shadow-lg border border-border/40">
        <div className="flex flex-col items-center space-y-6">
          {/* Logo */}
          <div className="w-24 h-24 flex items-center justify-center">
            <img
              src={navyLogo}
              alt="AndAI Logo"
              className="w-full h-full block dark:hidden"
            />
            <img
              src={whiteLogo}
              alt="AndAI Logo"
              className="w-full h-full hidden dark:block"
            />
          </div>

          {/* Message Content */}
          <div className="text-center space-y-4">
            <h1 className="text-2xl font-semibold text-foreground">
              We've Moved!
            </h1>
            <p className="text-lg text-foreground/90">
              app.tryandai.com has moved to{" "}
              <a
                href="https://platform.tryandai.com"
                className="text-primary hover:underline font-medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                platform.tryandai.com
              </a>
            </p>
            <div className="pt-4 border-t border-border/30">
              <p className="text-sm text-muted-foreground">
                Need access to this material? Contact{" "}
                <a
                  href="mailto:caleb@tryandai.com"
                  className="text-primary hover:underline font-medium"
                >
                  Caleb Harris
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;

/* Original implementation preserved below
import { Loader, SidebarPage } from "@/components";
import { Separator } from "@/components/ui/separator";
import { useProject, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType, Project, ProjectType } from "@/types";
import { useAuthInfo } from "@propelauth/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectTypeCard, ProjectsTable } from "./components";

function HomePage() {
  const navigate = useNavigate();
  const { getProjectMetadata } = useProject();
  const { getUserProjects, getSearchChatProjectId, getPortfolioMetadata } = useViz();
  const { user } = useAuthInfo();

  // Global state from store
  const {
    currentProjectId,
    updateCurrentProjectId,
    updateCurrentPortfolioId,
    updateCurrentParent,
    updateCurrentPortfolio,
    currentPortfolio,
  } = useProjectStore();
  const { addErrorMessage, searchChatProjectId, updateIsLoading } = useAppStateStore();

  const pageContentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExploreProjectId = useCallback(async () => {
    await getSearchChatProjectId();
  }, [getSearchChatProjectId]);

  // Fetches user projects on the first home page load
  const fetchProjectsData = useCallback(async () => {
    if (!user?.email) return;
    setIsLoading(true);
    const projectsResponse = await getUserProjects();
    if (!projectsResponse.success) {
      addErrorMessage("Error fetching projects. Please try again later.");
    }
    setIsLoading(false);
  }, [user?.email, getUserProjects, addErrorMessage]);

  // Handle project selection
  const handleClickProject = useCallback(
    async (project: Project) => {
      const id = project.project_id || project.id;
      if (project.type !== ProjectType.PFA && project.type !== ProjectType.SEP) {
        updateCurrentParent(ParentType.PROJECT);
        if (id === currentProjectId) {
          navigate(`/project/${id}/subject`);
        } else {
          navigate(`/project/${id}/subject`);
          await getProjectMetadata(id);
        }
      } else {
        navigate(`/portfolio/${id}/subjects`);
        await getPortfolioMetadata(id, false, true, project.name);
      }
    },
    [
      currentProjectId,
      getProjectMetadata,
      updateCurrentProjectId,
      updateCurrentParent,
      updateCurrentPortfolioId,
      updateCurrentPortfolio,
      currentPortfolio,
      navigate,
      getPortfolioMetadata,
      updateIsLoading,
    ],
  );

  useEffect(() => {
    if (!searchChatProjectId) {
      fetchExploreProjectId();
    }
  }, [searchChatProjectId, fetchExploreProjectId]);

  useEffect(() => {
    fetchProjectsData();
  }, []);

  return (
    <SidebarPage pageName="Home">
      <div className="bg-background" ref={pageContentRef}>
        <div id="createNewProject" className="mb-6 flex flex-col gap-2 pt-4 px-3">
          <p className="text-base font-medium p-1 h-auto whitespace-nowrap">
            Start a new project
          </p>
          <div className="flex space-x-4 overflow-x-auto">
            <ProjectTypeCard
              onClick={() => navigate("/create-project")}
              type={ProjectType.INV}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-portfolio")}
              type={ProjectType.PFA}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-application-project")}
              type={ProjectType.APP}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="gap-2">
            <div className="px-3">
              <Separator />
            </div>
            <ProjectsTable
              tableType="active"
              handleClickProject={handleClickProject}
              isInArchive={false}
              height="calc(100vh - 200px)"
            />
          </div>
        )}
      </div>
    </SidebarPage>
  );
}
*/
