/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useDocumentTitle } from "@/hooks";
import { RedirectToLogin } from "@propelauth/react";

/**
 * @description Log in page redirects to PropelAuth
 */
function LogInPage() {
  useDocumentTitle("Sign In");
  return (
    <RedirectToLogin
      postLoginRedirectUrl={`/home`} // Redirect to the home page after login
    />
  );
}

export default LogInPage;
